<script>
import axios from "axios";
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

/**
 * Senders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Senders",
      customers: {
        id_card_type: "",
        id_card: null,
        id_card_num: "",
        fname: "",
        lname: "",
        gender: "",
        place_birth: "",
        date_birth: "",
        occupation: "",
        country: "",
        province: "",
        city: "",
        address: "",
        nib: "",
        pic_name: "",
        pic_role: "",
        id_pic: "",
      },
      user_type: 2,
      submitted: false,
      showmodal: false,
      showmodal1: false,
      getCountry: [],
      getProvinces: [],
      citys: [],
      coOthers1: [{ nama_prov: "Others" }],
      coOthers2: [{ nama_city_ppatk: "Others" }],
      bisnisField: true,
      singleField: false,
      fileKTP: false,
      idCard: null,
      npwpCard: null,
      moaCard: null,
      nibCard: null,
      users: [],
      data: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      isDisable: false,
      fields: [
        { key: "user_types", label: "Type" },
        { key: "sender_name", label: "Sender Name" },
        { key: "idcard_num", label: "ID Card Number" },
        { key: "user_address", label: "Address" },
        { key: "user_country", label: "Country" },
        { key: "user_prov", label: "Province" },
        { key: "user_city", label: "City" },
        { key: "action" },
      ],
      isBusy: false,
    };
  },
  validations: {
    customers: {
      country: {
        required,
      },
      province: {
        required,
      },
      city: {
        required,
      },
    },
  },
  computed: {
    rows() {
      return this.users.length;
    },
  },
  mounted() {
    this.totalRows = this.users.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleSubmit() {
      this.submitted = true;
      this.isDisable = true;
      this.$v.customers.country.$touch();
      this.$v.customers.province.$touch();
      this.$v.customers.city.$touch();

      if (
        !this.$v.customers.country.$invalid &&
        !this.$v.customers.province.$invalid &&
        !this.$v.customers.city.$invalid
      ) {
        let user_hp = Math.floor(Math.random() * 1000000000000);
        let password = user_hp;
        let id_card_number = "AOB" + this.customers.id_card_num;
        let emailfix = id_card_number + "@adaremitoutbound.co.id";
        let idUser = JSON.parse(localStorage.getItem("user")).user_id;
        let admin = JSON.parse(localStorage.getItem("user")).id;

        if (this.user_type === 1) {
          let data = new FormData();
          data.append("type_user", this.user_type),
            data.append("affiliate", idUser),
            data.append("email", emailfix),
            data.append("password", password),
            data.append("c_residence", this.customers.country.name),
            data.append("hp", user_hp),
            data.append("f_name", this.customers.fname),
            data.append("l_name", this.customers.lname),
            data.append("gender", this.customers.gender),
            data.append("place_b", this.customers.place_birth),
            data.append("date_b", this.customers.date_birth),
            data.append("address", this.customers.address),
            data.append("country", this.customers.country.name),
            data.append("prov", this.customers.province.nama_prov),
            data.append("city", this.customers.city.nama_city_ppatk),
            data.append("citizen", this.customers.country.name),
            data.append("occupation", this.customers.occupation),
            data.append("phone", user_hp),
            data.append("card_type", this.customers.id_card_type),
            data.append("card_num", id_card_number),
            data.append("ktp", this.idCard),
            data.append("admin", admin),
            axios.post(`v1/register-aff`, data).then((res) => {
              if (res.data.success) {
                Swal.fire("Success", "Sender was created", "success");
                this.getSender();
                this.showmodal = false;
                this.submitted = false;
                this.isDisable = false;
                this.onChange();
              } else {
                Swal.fire("Error", res.data.message, "error");
                this.isDisable = false;
              }
            });
        } else {
          this.isDisable = true;
          let data = new FormData();
          data.append("type_user", this.user_type),
            data.append("affiliate", idUser),
            data.append("email", emailfix),
            data.append("password", password),
            data.append("hp", user_hp),
            data.append("phone", user_hp),
            data.append("card_num", id_card_number),
            data.append("card_type", "NPWP"),
            data.append("c_residence", this.customers.country.name),
            data.append("country", this.customers.country.name),
            data.append("prov", this.customers.province.nama_prov),
            data.append("city", this.customers.city.nama_city_ppatk),
            data.append("citizen", this.customers.country.name),
            data.append("address", this.customers.address),
            data.append("bisnis_name", this.customers.fname),
            data.append("pic_name", this.customers.pic_name),
            data.append("pic_role", this.customers.pic_role),
            data.append("id_pic", this.customers.id_pic),
            data.append("nib", this.customers.nib),
            data.append("ktp", this.idCard),
            data.append("nib", this.nibCard),
            data.append("npwp", this.npwpCard),
            data.append("moa", this.moaCard),
            data.append("admin", admin),
            axios.post(`v1/register-aff`, data).then((res) => {
              if (res.data.success) {
                Swal.fire("Success", "Sender was created", "success");
                this.getSender();
                this.showmodal = false;
                this.submitted = false;
                this.isDisable = false;
              } else {
                Swal.fire("Error", res.data.message, "error");
                this.submitted = false;
                this.isDisable = false;
              }
            });
        }
      } else {
        Swal.fire("Warning", "Select Required", "error");
        this.submitted = false;
        this.isDisable = false;
      }
    },
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
    },
    onChange() {
      (this.user_type = 1),
        (this.singleField = true),
        (this.bisnisField = false),
        (this.customers.id_card_type = ""),
        (this.customers.id_card_num = ""),
        (this.customers.fname = ""),
        (this.customers.lname = ""),
        (this.customers.phone = ""),
        (this.customers.gender = ""),
        (this.customers.place_birth = ""),
        (this.customers.date_birth = ""),
        (this.customers.occupation = ""),
        (this.customers.country = ""),
        (this.customers.province = ""),
        (this.customers.city = ""),
        (this.customers.address = ""),
        (this.customers.pic_name = ""),
        (this.customers.pic_role = ""),
        (this.customers.id_pic = ""),
        (this.customers.nib = "");
    },
    onChange2() {
      (this.user_type = 2),
        (this.singleField = false),
        (this.bisnisField = true),
        (this.customers.id_card_type = ""),
        (this.customers.id_card_num = ""),
        (this.customers.fname = ""),
        (this.customers.lname = ""),
        (this.customers.phone = ""),
        (this.customers.gender = ""),
        (this.customers.place_birth = ""),
        (this.customers.date_birth = ""),
        (this.customers.occupation = ""),
        (this.customers.country = ""),
        (this.customers.province = ""),
        (this.customers.city = ""),
        (this.customers.address = ""),
        (this.customers.pic_name = ""),
        (this.customers.pic_role = ""),
        (this.customers.id_pic = ""),
        (this.customers.nib = "");
    },
    getCountrys() {
      axios
        .get("/v1/prefix")
        .then((res) => {
          this.getCountry = res.data.country;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProvince() {
      this.customers.province = "";
      this.customers.city = "";
      let countryId = this.customers.country.dial_code;
      axios
        .get(`/v1/province/${countryId}`)
        .then((res) => {
          this.getProvinces = res.data.province;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCity() {
      this.customers.city = "";
      let id = this.customers.province.kode_prov;
      axios
        .get("/v1/city/" + id)
        .then((res) => {
          this.citys = res.data.province;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.customers.id_card = files[0];
      this.fileKTP = true;
    },
    getSender() {
      this.isBusy = true;
      let id = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-sender/${id}`).then((res) => {
        this.isBusy = false;
        this.users = res.data.user;
        if (!JSON.parse(localStorage.getItem("user")).id) {
          let Item = { key: "admin_name" };
          this.fields.splice(7, 0, Item);
        }
      });
    },
    deletes(e) {
      let id = e.item.user_id;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger ml-2",
          cancelButton: "btn btn-secondary",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            axios.post(`/v1/del_users/${id}`).then((res) => {
              if (res.data.success) {
                this.getSender();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Sender has been deleted.",
                  "success"
                );
              }
            });
          }
        });
    },
    detail(e) {
      this.showmodal1 = true;
      let id = e.item.user_id;
      axios.get(`v1/get-user/${id}`).then((res) => {
        this.data = res.data.user[0];
      });
    },
    onChangeKTP(e) {
      this.idCard = e.target.files[0];
    },
    onChangeNPWP(e) {
      this.npwpCard = e.target.files[0];
    },
    onChangeNIB(e) {
      this.nibCard = e.target.files[0];
    },
    onChangeMOA(e) {
      this.moaCard = e.target.files[0];
    },
  },
  created() {
    this.getCountrys();
    this.getSender();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a
                href="javascript:void(0);"
                class="btn btn-primary mb-2"
                @click="showmodal = true"
              >
                <i class="mdi mdi-plus mr-2"></i> Add Sender
              </a>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-right"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :items="users"
                        :fields="fields"
                        striped
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="isBusy"
                        show-empty
                        fixed
                      >
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: field.key === 'user_address' || field.key === 'idcard_num' || field.key === 'sender_name' ? '190px' : '125px' }"
                        >
                        </template>
                        <template v-slot:cell(admin)="row">
                          <div>{{ row.item.admin_name }}</div>
                        </template>
                        <template v-slot:cell(action)="row">
                          <a
                            @click="detail(row)"
                            href="javascript:void(0);"
                            class="mr-3 text-info"
                            v-b-tooltip.hover
                            title="Detail"
                          >
                            <i
                              class="mdi mdi-file-eye"
                              style="font-size:1.3rem"
                            ></i>
                          </a>
                          <a
                            @click="deletes(row)"
                            href="javascript:void(0);"
                            class="mr-3 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                          >
                            <i
                              class="mdi mdi-trash-can"
                              style="font-size:1.3rem"
                            ></i>
                          </a>
                        </template>
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle mr-2"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template #empty>
                          <h5 class='empty'>No Senders Data ...</h5>
                        </template>
                      </b-table>
                    </div>
                    <!-- <template v-else>
                      <h5 class="empty">No Users Data ...</h5>
                    </template> -->
                    <div class="row">
                      <div class="col">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Add Sender"
      title-class="text-dark font-18"
      hide-footer
      scrollable
      @show="onChange2"
      @hidden="onChange2"
    >
      <form @submit.prevent="handleSubmit">
        <b-tabs content-class="p-3 text-muted">
          <b-tab active class="border-0" @click="onChange2">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Business</span>
            </template>
            <div class="form-group row">
              <label for="nib">Upload NIB</label>
              <input
                id="nib"
                type="file"
                class="form-control"
                @input="onChangeNIB"
                :required="bisnisField"
              />
            </div>
            <div class="form-group row">
              <label for="npwp">Upload NPWP</label>
              <input
                id="npwp"
                type="file"
                class="form-control"
                @input="onChangeNPWP"
                :required="bisnisField"
              />
            </div>

            <div class="form-group row">
              <label for="moa">Upload AKTA</label>
              <input
                id="moa"
                type="file"
                class="form-control"
                @input="onChangeMOA"
                :required="bisnisField"
              />
            </div>
            <div class="form-group row">
              <label for="id_card_num">NPWP Number</label>
              <input
                id="id_card_num"
                v-model="customers.id_card_num"
                :required="bisnisField"
                type="text"
                class="form-control"
                placeholder="Enter NPWP Number"
                minlength="15"
                maxlength="16"
              />
            </div>
            <div class="form-group row">
              <label for="nib">NIB Number</label>
              <input
                id="nib"
                v-model="customers.nib"
                type="text"
                class="form-control"
                placeholder="Enter NIB Number"
                :required="bisnisField"
                minlength="13"
                maxlength="13"
              />
            </div>
            <div class="form-group row">
              <label for="com_name">Company Name</label>
              <input
                id="com_name"
                v-model="customers.fname"
                type="text"
                class="form-control"
                placeholder="Enter Company Name"
                :required="bisnisField"
              />
            </div>
            <div class="form-group row">
              <label for="country">Nationality</label>
              <multiselect
                v-model="customers.country"
                :options="getCountry"
                label="name"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                placeholder="Select Country"
                @input="getProvince"
                :required="bisnisField"
              ></multiselect>
              <small
                class="mb-0 text-danger"
                v-if="
                  !$v.customers.country.required && $v.customers.country.$error
                "
              >
                Select Nationality is required
              </small>
            </div>
            <div class="form-group row">
              <label for="province">Province</label>
              <multiselect
                v-if="customers.country.name == 'Indonesia'"
                v-model="customers.province"
                :options="getProvinces"
                label="nama_prov"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                placeholder="Select Province"
                @input="getCity"
                :required="bisnisField"
              ></multiselect>
              <multiselect
                v-else
                v-model="customers.province"
                label="nama_prov"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                :options="coOthers1"
                placeholder="Select Province"
                @input="getCity"
                :required="bisnisField"
              ></multiselect>
              <small
                class="mb-0 text-danger"
                v-if="
                  !$v.customers.province.required &&
                    $v.customers.province.$error
                "
              >
                Select Province is required
              </small>
            </div>
            <div class="form-group row">
              <label for="city">City</label>
              <multiselect
                v-if="customers.country.name == 'Indonesia'"
                v-model="customers.city"
                :options="citys"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                label="nama_city_ppatk"
                placeholder="Select City"
                :required="bisnisField"
              ></multiselect>
              <multiselect
                v-else
                v-model="customers.city"
                :options="coOthers2"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                label="nama_city_ppatk"
                placeholder="Select City"
                :required="bisnisField"
              ></multiselect>
              <small
                class="mb-0 text-danger"
                v-if="!$v.customers.city.required && $v.customers.city.$error"
              >
                Select City is required
              </small>
            </div>
            <div class="form-group row">
              <label for="address">Address</label>
              <textarea
                v-model="customers.address"
                class="form-control"
                rows="3"
                placeholder="Enter Address"
                :required="bisnisField"
              ></textarea>
            </div>
            <hr />
            <div class="form-group row">
              <label for="pic_name">Director / PIC Name</label>
              <input
                id="pic_name"
                v-model="customers.pic_name"
                type="text"
                class="form-control"
                placeholder="Enter PIC Name"
                :required="bisnisField"
              />
            </div>
            <div class="form-group row">
              <label for="pic_role">Director / PIC Role</label>
              <input
                id="pic_role"
                v-model="customers.pic_role"
                type="text"
                class="form-control"
                placeholder="Enter PIC Role"
                :required="bisnisField"
              />
            </div>
            <div class="form-group row">
              <label for="id_card">Upload Director / PIC ID Card</label>
              <input
                id="id_card"
                type="file"
                class="form-control"
                @input="onChangeKTP"
                :required="bisnisField"
              />
            </div>
            <div class="form-group row">
              <label for="id_pic">Director / PIC ID Card Number</label>
              <input
                id="id_pic"
                v-model="customers.id_pic"
                type="text"
                class="form-control"
                placeholder="Enter PIC ID Card Number"
                :required="bisnisField"
              />
            </div>
            <div class="text-right">
              <b-button @click="hideModal">Cancel</b-button>
              <button
                type="submit"
                class="btn btn-success ml-1"
                style="width:5rem"
                :disabled="isDisable"
              >
                <span v-if="submitted"
                  ><b-spinner
                    class="p-0"
                    style="width:20px;height:20px"
                    variant="black"
                    role="status"
                  ></b-spinner
                ></span>
                <span v-else>Submit</span>
              </button>
            </div>
          </b-tab>
          <b-tab class="border-0" @click="onChange">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Personal</span>
            </template>
            <div class="form-group row">
              <label for="id_card_type">ID CARD Type</label>
              <select
                class="form-control"
                v-model="customers.id_card_type"
                :required="singleField"
              >
                <option value="" hidden>Select ID Card Type</option>
                <option value="KTP">KTP</option>
                <option value="SIM">SIM</option>
                <option value="Passport">Passport</option>
              </select>
            </div>
            <div class="form-group row">
              <label for="id_card">Upload ID CARD</label>
              <input
                id="id_card"
                type="file"
                class="form-control"
                @input="onChangeKTP"
                :required="singleField"
              />
            </div>
            <div class="form-group row">
              <label for="id_card_num">ID Card Number</label>
              <input
                v-if="customers.id_card_type == 'KTP'"
                id="id_card_num"
                v-model="customers.id_card_num"
                :required="singleField"
                type="text"
                class="form-control"
                placeholder="Enter ID Card Number"
                minlength="16"
                maxlength="16"
              />
              <input
                v-if="customers.id_card_type == 'SIM'"
                id="id_card_num"
                v-model="customers.id_card_num"
                :required="singleField"
                type="text"
                class="form-control"
                placeholder="Enter ID Card Number"
                minlength="12"
                maxlength="14"
              />
              <input
                v-if="customers.id_card_type == 'Passport'"
                id="id_card_num"
                v-model="customers.id_card_num"
                :required="singleField"
                type="text"
                class="form-control"
                placeholder="Enter ID Card Number"
                minlength="7"
                maxlength="9"
              />
            </div>
            <div class="form-group row">
              <label for="fname">First Name</label>
              <input
                id="fname"
                v-model="customers.fname"
                type="text"
                class="form-control"
                placeholder="Enter First Name"
                :required="singleField"
              />
            </div>
            <div class="form-group row">
              <label for="lname">Last Name</label>
              <input
                id="lname"
                v-model="customers.lname"
                type="text"
                class="form-control"
                placeholder="Enter Last Name"
                :required="singleField"
              />
            </div>
            <div class="form-group row">
              <label for="gender">Gender</label>
              <select
                class="form-control"
                v-model="customers.gender"
                :required="singleField"
              >
                <option value="" hidden>Select Gender</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
            </div>
            <div class="form-group row">
              <label for="place_birth">Place Birth</label>
              <input
                id="place_birth"
                v-model="customers.place_birth"
                type="text"
                class="form-control"
                placeholder="Enter Place Birth"
                :required="singleField"
              />
            </div>
            <div class="form-group row">
              <label for="date_birth">Date Birth</label>
              <input
                id="date_birth"
                v-model="customers.date_birth"
                type="date"
                class="form-control"
                placeholder="Select Date Birth"
                :required="singleField"
              />
            </div>
            <div class="form-group row">
              <label for="occupation">Occupation</label>
              <input
                id="occupation"
                v-model="customers.occupation"
                type="text"
                class="form-control"
                placeholder="Enter Occupation"
                :required="singleField"
              />
            </div>
            <div class="form-group row">
              <label for="country">Nationality</label>
              <multiselect
                v-model="customers.country"
                :options="getCountry"
                label="name"
                placeholder="Select Country"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                @input="getProvince"
                :required="singleField"
              ></multiselect>
              <small
                class="mb-0 text-danger"
                v-if="
                  !$v.customers.country.required && $v.customers.country.$error
                "
              >
                Select Nationality is required
              </small>
            </div>
            <div class="form-group row">
              <label for="province">Province</label>
              <multiselect
                v-if="customers.country.name == 'Indonesia'"
                v-model="customers.province"
                :options="getProvinces"
                label="nama_prov"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                placeholder="Select Province"
                @input="getCity"
                :required="singleField"
              ></multiselect>
              <multiselect
                v-else
                v-model="customers.province"
                label="nama_prov"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                :options="coOthers1"
                placeholder="Select Province"
                @input="getCity"
                :required="singleField"
              ></multiselect>
              <small
                class="mb-0 text-danger"
                v-if="
                  !$v.customers.province.required &&
                    $v.customers.province.$error
                "
              >
                Select Province is required
              </small>
            </div>
            <div class="form-group row">
              <label for="city">City</label>
              <multiselect
                v-if="customers.country.name == 'Indonesia'"
                v-model="customers.city"
                :options="citys"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                label="nama_city_ppatk"
                placeholder="Select City"
                :required="singleField"
              ></multiselect>
              <multiselect
                v-else
                v-model="customers.city"
                :options="coOthers2"
                :selectLabel="null"
                :deselectLabel="null"
                :allow-empty="false"
                label="nama_city_ppatk"
                placeholder="Select City"
                :required="singleField"
              ></multiselect>
              <small
                class="mb-0 text-danger"
                v-if="!$v.customers.city.required && $v.customers.city.$error"
              >
                Select City is required
              </small>
            </div>
            <div class="form-group row">
              <label for="address">Address</label>
              <textarea
                v-model="customers.address"
                class="form-control"
                rows="3"
                placeholder="Enter Address"
                :required="singleField"
              ></textarea>
            </div>
            <div class="text-right">
              <b-button variant="secondary" @click="hideModal">Cancel</b-button>
              <button
                type="submit"
                class="btn btn-success ml-1"
                style="width:5rem"
                :disabled="isDisable"
              >
                <span v-if="submitted"
                  ><b-spinner
                    class="p-0"
                    style="width:20px;height:20px"
                    variant="black"
                    role="status"
                  ></b-spinner
                ></span>
                <span v-else>Submit</span>
              </button>
            </div>
          </b-tab>
        </b-tabs>
      </form>
    </b-modal>
    <b-modal
      id="modal-1"
      v-model="showmodal1"
      title="Sender Details"
      title-class="text-dark font-18"
      hide-footer
      scrollable
      centered
    >
      <form>
        <template>
          <div class="table-responsive table-striped">
            <table class="table mb-0">
              <tbody>
                <tr v-if="data.user_types">
                  <th scope="row">Account Type</th>
                  <td>{{ data.user_types }}</td>
                </tr>
                <tr v-if="data.sender_name">
                  <th scope="row">Full Name</th>
                  <td>{{ data.sender_name }}</td>
                </tr>
                <tr v-if="data.idcard_num">
                  <th scope="row">ID Card Number</th>
                  <td>{{ data.idcard_num }}</td>
                </tr>
                <tr v-if="data.nib">
                  <th scope="row">NIB</th>
                  <td>{{ data.nib }}</td>
                </tr>
                <tr v-if="data.user_country">
                  <th scope="row">Country</th>
                  <td>{{ data.user_country }}</td>
                </tr>
                <tr v-if="data.user_prov">
                  <th scope="row">Province</th>
                  <td>{{ data.user_prov }}</td>
                </tr>
                <tr v-if="data.user_city">
                  <th scope="row">City</th>
                  <td>{{ data.user_city }}</td>
                </tr>
                <tr v-if="data.user_address">
                  <th scope="row">Address</th>
                  <td>{{ data.user_address }}</td>
                </tr>
                <tr v-if="data.pic_name">
                  <th scope="row">PIC Name</th>
                  <td>{{ data.pic_name }}</td>
                </tr>
                <tr v-if="data.company_role">
                  <th scope="row">PIC Role</th>
                  <td>{{ data.company_role }}</td>
                </tr>
                <tr v-if="data.id_pic">
                  <th scope="row">ID PIC</th>
                  <td>{{ data.id_pic }}</td>
                </tr>
                <tr v-if="data.admin_name">
                  <th scope="row">Admin Name</th>
                  <td>{{ data.admin_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>
